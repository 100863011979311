.fam-div {
    width: 100%;
    /* height: 80%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;
    margin-bottom: 2rem;

}

.fam-content {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.fam-content1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10px;
}

.fam-content1 h1 {
    padding-bottom: 10px;
    font-size: 25px;
}

.fam-content1 p {
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 15px;
    text-align: justify;
}

.fam-image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.fam-scroll {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

}

.fam-scroll h1 {
    color: var(--primary-color);
    margin-left: 0rem;
    padding: 10px;
    font-size: 18px;

}

.fam-con-images {
    width: 100%;
    height: 76%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.fam-con-images img {
    width: 30rem;
    height: 30rem;
    object-fit: cover;
    border-radius: 10px;
}

.addbtn {
    font-weight: bold;
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
}

.fam-cont {
    text-align: center;
    line-height: 25px;
    padding: 10px;
}

.fam-cont p {
    font-size: 16px;
    font-family: var(--primary-font);
    font-weight: bold;
    color: grey;
    padding: 5px;
}

.fam2-croll {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    overflow-y: scroll;
    padding: 10px 0px;
    padding-left: 50px;
    margin-top: 10px;
}

.fam2-croll::-webkit-scrollbar {
    display: none;
}

.fam1-croll {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    margin-left: 20px;
    border-radius: 0px;
    margin-bottom: 20px;
    transition: .5s;
    width: 100%;
    border-bottom: 1px solid gray;
    cursor: pointer;

}

.fam1-croll img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
}

.fam1-croll:hover {
    background-color: #333333;
}

.fam1-croll-active {
    background-color: #333333;
    color: white;
    cursor: pointer;
}

.fam1-croll:hover .fam-detail h3 {
    color: white;
}

.fam1-croll:hover .fam-detail p {
    color: white;
}

.fam-detail {
    padding-left: 20px;
    line-height: 25px;
}

.fam-detail h3 {
    font-size: 20px;
    transition: .5s;
    text-align: left;
}

.fam-detail p {
    font-size: 16px;
    transition: .5s;
    text-align: left;
}

.fam-tit {
    width: 91%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid grey;
}

.newsmodel {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid red;
    background-color: white !important;
    overflow: auto;
    border-radius: 15px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    width: 50% !important;
    transform: translate(-50%, -50%);
    border: 1px solid lightgray !important;
    margin-top: -35px;
    height: 80%;
    overflow: scroll;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    z-index: 100;
}

.newsmodel::-webkit-scrollbar {
    display: none;
}

.roletext {
    color: #D1112B;
    font-size: 15px;
    font-weight: bold;
}

.closebtn {
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
    background-color: black;
    color: white;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    font-weight: bold;
}

.modelhead {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    margin-top: 1rem;
    margin-bottom: 1rem;
    white-space: nowrap;
    border: 1px solid #e5e7eb;
}

.inputfamily {
    width: 100%;
    /* height: 30px; */
    padding-left: 10px;
    padding-bottom: 20px;
    border: none;
    color: black;
    font-weight: bold;
    font-family: var(--Primary-font);
    margin-bottom: 15px;
    outline: none;
    border-bottom: 1px solid black;
    background-color: transparent;
}

.label-text {
    font-size: 1rem;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: hsl(var(--bc) / var(--tw-text-opacity));
    font-weight: 700;
    margin-bottom: 5px;
}

.savebtn {
    background-color: #333333;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.addicon {
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .fam-content {
        display: block !important;
        width: 90% !important;
    }

    .fam-tit {
        width: 100%;
    }

    .fam2-croll {
        padding-left: 0;
    }

    .fam-scroll {
        margin-top: 2rem;
    }

    .fam-scroll h1 {
        color: var(--primary-color);
        margin-left: 0rem;
        padding: 10px 0px;
        font-size: 18px;
    }

    .newsmodel {
        position: absolute;
        inset: 50% auto auto 50%;
        border: 1px solid red;
        background-color: white !important;
        overflow: auto;
        border-radius: 15px;
        outline: none;
        padding: 20px;
        margin-right: -50%;
        width: 90% !important;
        transform: translate(-50%, -50%);
        border: 1px solid lightgray !important;
        margin-top: 35px;
        height: 62%;
        overflow: scroll;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        z-index: 9999 !important;
    }

    .fam-con-images img {
        width: 22rem;
        height: 22rem;
        object-fit: cover;
        border-radius: 10px;
    }
}

.newlabel {
    margin-top: 5px;
    margin-bottom: 5px;
}