.gallery-title {
    width: 100%;
    height: auto;
    padding: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    z-index: -1;

}

.gallery1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.gallery1 h1 {
    font-size: 25px;
    color: var(--primary-color);
    font-weight: bold;
    padding-bottom: 10px;
}

.gallery1 p {
    font-size: 24px;
    font-family: var(--primary-font);
    line-height: 25px;
    font-size: 16px;
    font-weight: bold;
    width: 600px;
    text-align: center;
}

.gallery-con {
    width: 100%;
    text-align: center;
    padding-top: 15px;
    line-height: 25px;
}

.gallery-con h3 {
    font-size: 20px;
}

.gallery-con h6 {
    font-size: 16px;
    padding: 8px 0px;
    color: gray;
}

.galery-slider {
    width: 100%;
    height: auto;
    margin-top: 3rem;
    padding-left: 9.5rem;
    padding-right: 9.5rem;
}

.swipe1 {
    width: 100%;
    height: auto;
}

.galery-slider1 {
    width: 100%;
    height: 100%;
}

.slides1 {
    width: 300px;
    height: auto;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
}

.slides1 img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

@media screen and (max-width:768px) {
    .galery-slider {
        width: 100%;
        height: auto;
        margin-top: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}