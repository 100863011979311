:root {
    --Primary-font: 'Quicksand', sans-serif;
}

.Register {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: hidden;
}

.Register1 {
    width: 90%;
    height: 100%;
    position: relative;
}

.Register1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.Register1::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
}

.Register2 {
    width: 100%;
    height: 100%;
    background-image: url(../images/bg\ 1.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo img {
    width: 100px;
    margin-bottom: 12px;
}

.Register2 button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.logo p {
    padding: 3px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(155, 154, 155, 1);
}

.register-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.register-title h1 {
    font-size: 20px;
    padding: 10px;
    margin-top: 20px;
}

.inputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 25px;
}

.inputs input {
    width: 400px;
    height: 30px;
    padding-left: 10px;
    padding-bottom: 5px;
    border: none;
    color: black;
    font-weight: bold;
    font-family: var(--Primary-font);
    margin-bottom: 25px;
    outline: none;
    border-bottom: 1px solid black;
    background-color: transparent;
}

.forget {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    margin: 10px 0px;
}

.forget p {
    font-size: 13px;
    font-family: var(--Primary-font);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forget p input {
    margin-right: 10px;
}

.forget p[name] {
    color: blue;
}

.Register2 button {
    margin: 10px 0px;
    font-size: 16px;
    width: 500px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    border: none;
    border-radius: 6px;
    transition: 0.7s;
    color: white;
    font-family: var(--Primary-font);
    background-color: black;

}

.icons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 0px;
}

.icons .icon1 {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 8px;
    margin: 15px;
    border-radius: 100%;
    background-color: rgba(58, 88, 152, 1);
}

.icons .icon2 {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 8px;
    margin: 15px;
    border-radius: 100%;
    background-color: rgba(205, 51, 50, 1);
}

.icons .icon3 {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 8px;
    margin: 15px;
    border-radius: 100%;
    background-color: rgba(1, 172, 239, 1);

}

.icons .icon4 {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 8px;
    margin: 15px;
    border-radius: 100%;
    background-color: rgba(254, 1, 0, 1);


}

@media screen and (Max-width:768px) {
    :root {
        --Primary-font: 'Quicksand', sans-serif;
    }

    .Register {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow-y: hidden;
        background-color: red;
    }

    .Register1 {
        width: 100%;
        height: 100%;
        position: relative;
        display: none;
    }

    .Register1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .Register1::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 1;
    }

    .Register2 {
        width: 100%;
        height: 100%;
        background-image: url(../images/bg\ 1.jpg);
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
    }

    .logo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .logo img {
        width: 100px;
        margin-bottom: 12px;
    }

    .Register2 button:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .logo p {
        padding: 3px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(155, 154, 155, 1);
    }

    .register-title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    .register-title h1 {
        font-size: 20px;
        padding: 10px;
        margin-top: 20px;
    }

    .inputs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 25px;
    }

    .inputs input {
        width: 400px;
        height: 30px;
        padding-left: 10px;
        padding-bottom: 5px;
        border: none;
        color: black;
        font-weight: bold;
        font-family: var(--Primary-font);
        margin-bottom: 25px;
        outline: none;
        border-bottom: 1px solid black;
        background-color: transparent;
    }

    .forget {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 90%;
        margin: 10px 0px;
    }

    .forget p {
        font-size: 13px;
        font-family: var(--Primary-font);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .forget p input {
        margin-right: 10px;
    }

    .forget p[name] {
        color: blue;
    }

    .Register2 button {
        margin: 10px 0px;
        font-size: 16px;
        width: 100%;
        height: 40px;
        cursor: pointer;
        text-align: center;
        border: none;
        border-radius: 6px;
        transition: 0.7s;
        color: white;
        font-family: var(--Primary-font);
        background-color: black;

    }

    .icons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 10px 0px;
    }

    .icons .icon1 {
        color: white;
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 8px;
        margin: 15px;
        border-radius: 100%;
        background-color: rgba(58, 88, 152, 1);
    }

    .icons .icon2 {
        color: white;
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 8px;
        margin: 15px;
        border-radius: 100%;
        background-color: rgba(205, 51, 50, 1);
    }

    .icons .icon3 {
        color: white;
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 8px;
        margin: 15px;
        border-radius: 100%;
        background-color: rgba(1, 172, 239, 1);

    }

    .icons .icon4 {
        color: white;
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 8px;
        margin: 15px;
        border-radius: 100%;
        background-color: rgba(254, 1, 0, 1);


    }


}