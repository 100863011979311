*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  width: 100vw;
  height:100vh;
  font-family: var(--Primary-font);
}
:root{
  --Primary-font:'Quicksand', sans-serif;
}
:root{
  --primary-color:rgba(209, 17, 43, 1)
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
