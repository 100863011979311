:root {
    --Primary-font: 'Quicksand', sans-serif;
}

.home-mission {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    padding-top: 3rem;
    margin-bottom: 20px;
}

.home-mission1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mission-image {
    width: 40%;
    height: 265px;
    border-radius: 10px;
}

.mission-image img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mission-content {
    width: 65%;
    height: auto;
    padding-left: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.mission-title h1 {
    font-size: 20px;
    color: var(--primary-color);
    padding-bottom: 15px;

}

.mission-title p {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    padding-bottom: 20px;
    text-align: justify;
}

.our-mission {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 10px;
    border-top: 1px solid gray;

}

.mission1 {
    width: 280px
}

.mission1 h1 {
    font-size: 20px;
    text-align: justify;
    padding: 10px 0px;
    text-align: center;
}

.mission1 p {
    font-size: 15px;
    font-weight: bold;
    text-align: justify;
    line-height: 23px;
    color: #333333;
}

@media screen and (max-width:768px) {
    .mission-title h1 {
        font-size: 20px;
        color: var(--primary-color);
        padding-bottom: 15px;
        margin-top: 10px;
        text-align: start;
    }

    .home-mission {
        padding-top: 1rem;
    }

    .home-mission1,
    .homeswiper1,
    .home-history1,
    .home-leader1,
    .home-news1,
    .home-news-slider1 {
        width: 90% !important;
    }

    .mission1 {
        width: 100%;
        padding: 20px 0px;
    }

    .mission1 h1 {
        text-align: start;
    }

    .history-images img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        margin-top: 10px;
    }

    .sscroll img {
        width: 100%;
        height: 25rem !important;
        border-radius: 10px;
    }

    .mission-title p {
        text-align: justify;
    }

    .home-mission1 {
        flex-direction: column;
    }

    .our-mission {
        flex-direction: column;
    }
}