.member-head {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.memberlabel {
    font-size: 13px;
    color: var(--primary-color);
    text-transform: capitalize;
    margin-bottom: 5px;
    text-align: left;
}

.member-head1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 20px 0px;
}

.member-head1profile {
    width: 80%;
    height: 100%;
    padding: 20px 0px;
}

.profilehead1 {
    text-align: left;
    color: #d1112b;
}

.member-head1 h1 {
    font-size: 25px;
    padding: 20px 0px;
    color: var(--primary-color);
}

.member-head1 p {
    line-height: 25px;
    text-align: center;
    width: 650px;
    font-weight: bold;
}

.member-search {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.member-search1 {
    width: 80%;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: whitesmoke;
    border-radius: 15px;
}

.member-search1 p {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid gray;
    background-color: whitesmoke;

    display: flex;
    align-items: center;
    justify-content: center;
}

.member-search1 input {
    width: 95%;
    height: 100%;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 16px;
    font-family: var(--Primary-font);
    background-color: whitesmoke;

    margin-left: 10px;
}

.member-search1 p .members-icon {
    font-size: 20px;
}

.member-content {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    margin-bottom: 3rem;
}

.member-contents1 {
    width: 80%;
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.member-cards {
    width: 100%;
    height: 100%;
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 30px;
}

.member-cardsnew {
    width: 100%;
    height: 100%;
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-gap: 30px;
}

.kulam-cards {
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    grid-gap: 30px;
    padding-left: 12rem;
    padding-right: 12rem;
    margin-bottom: 20px;
}

.member-card1 {
    width: 100%;
    height: 150px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    border: 1px solid gray;
    border-radius: 10px;
}

.kulamtitle {
    text-align: left !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.backdiv {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 1rem 12rem;
    cursor: pointer;
}

.backicon {
    font-size: 30px;
}

.member-box1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.familybox {
    border: 1px solid gray;
    padding: 0.5rem
}

.btnlist {
    display: flex;
    gap: 1rem;
}

.member-img-con {
    width: 100%;
    padding-left: 20px;
}

.member-img {
    width: 150px;
    height: 100%;
    object-fit: cover;
}

.member-img img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.member-contact {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.member-img-con h2 {
    font-size: 18px;
    padding-bottom: 5px;
    text-align: left;
}

.member-img-con p {
    font-size: 14px;
    color: gray;
    text-transform: capitalize;
    text-align: left;
}

.member-contact p {
    font-size: 16px;
    padding-bottom: 5px;
    font-weight: bold;
    display: flex;
    /* text-transform: lowercase;
      */
}

.member-contact p .member-i {
    font-size: 16px;
    margin-right: 10px;
    text-transform: lowercase;
}

.member-contact p a {
    color: black;
    text-transform: lowercase;
}

.member-contents1 h1 {
    font-size: 20px;
}

.search-scroll {
    width: 100%;

    padding-top: 0px;
}

.search-scroll p[name] {
    width: 100%;
    height: 50px;
    background-color: whitesmoke;
    border-bottom: 1px solid gray;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: gray;
}

.search-scroll p .search-scroll-icon {
    font-size: 20px;
}

.filterdiv {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    display: grid;
    padding: 0 12rem;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    column-gap: 2rem;
    margin-top: 2rem;
}

.selectdivfilter {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid gray;
    border-radius: 5px;
    font-weight: 700;
}

.selectdivfilter option {
    text-transform: capitalize;
}

.resetbtn {
    background-color: transparent;
    border: 1px solid gray;
    padding: 0.7rem 4rem;
    font-weight: 600;
    border-radius: 5px;
    width: 100%;
}

.resetbtn:hover {
    background-color: black;
    color: white;
}

@media screen and (max-width: 600px) {
    .member-head {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
    }

    .kulam-cards {
        padding-top: 40px;
        display: grid;
        grid-template-columns: repeat(1, 2fr);
        grid-gap: 30px;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 20px;
    }

    .shortline {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 16px;
    }

    .backdiv {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        padding: 1rem 1rem;
        cursor: pointer;
    }

    .backicon {
        font-size: 25px;
    }

    .member-head1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 0px;
    }

    .member-head1 h1 {
        font-size: 25px;
        padding: 10px 0px;
        color: var(--primary-color);
    }

    .member-head1 p {
        line-height: 25px;
        text-align: center;
        width: 100%;
        font-weight: bold;
    }

    .member-search {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }

    .member-search1 {
        width: 100%;
        height: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        background-color: whitesmoke;
        border-radius: 15px;
        margin-top: 10px;
    }

    .member-search1 p {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid gray;
        background-color: whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .member-search1 input {
        width: 95%;
        height: 100%;
        border: none;
        outline: none;
        padding: 5px 0px;
        font-size: 16px;
        font-family: var(--Primary-font);
        background-color: whitesmoke;

        margin-left: 0px;
    }

    .memberlabel {
        font-size: 10px;
        color: var(--primary-color);
        text-transform: capitalize;
        margin-bottom: 5px;
        text-align: left;
    }

    .member-search1 p .members-icon {
        font-size: 20px;
    }

    .search-scroll {
        width: 100%;

        padding-top: 0px;
    }

    .search-scroll p[name] {
        width: 100%;
        height: 50px;
        background-color: whitesmoke;
        border-bottom: 1px solid gray;
        margin: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px;
        color: gray;
    }

    .search-scroll p .search-scroll-icon {
        font-size: 20px;
    }

    .member-content {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 25px 0px;
        margin-bottom: 3rem;
        padding: 0px 10px;
    }

    .member-contents1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }

    .member-cards {
        width: 100%;
        height: 100%;
        padding-top: 40px;
        display: grid;
        grid-template-columns: repeat(1, 4fr);
        grid-gap: 30px;
    }

    .member-card1 {
        width: 100%;
        height: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        border: 1px solid gray;
        border-radius: 10px;
    }

    .member-box1 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .member-img-con {
        width: 100%;
        padding-left: 10px;
    }

    .member-img {
        width: 70px;
        height: 100%;
        object-fit: cover;
    }

    .member-img img {
        border-radius: 10px;
        height: 3rem;
        width: 3rem;
        object-fit: cover;
    }

    .member-contact {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }

    .member-img-con h2 {
        font-size: 10px;
        padding-bottom: 5px;
        text-align: left;
    }

    .member-img-con p {
        font-size: 12px;
        color: gray;
        text-align: left;
    }

    .member-contact p {
        font-size: 10px;
        padding-bottom: 5px;
        font-weight: bold;
        text-transform: lowercase;
    }

    .member-contact p .member-i {
        font-size: 12px;
        margin-right: 5px;
    }

    .member-contact p a {
        color: black;
        text-transform: lowercase;
    }

    .member-contents1 h1 {
        font-size: 16px;
    }

    .filterdiv {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        display: grid;
        padding: 0px 1rem;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        column-gap: 2rem;
        margin-top: 2rem;
    }

    .selectdivfilter {
        width: 100%;
        padding: 0.7rem;
        border: 1px solid gray;
        border-radius: 5px;
        font-weight: 700;
        margin-bottom: 18px;
    }

    .resetbtn {
        background-color: transparent;
        border: 1px solid gray;
        padding: 0.7rem 4rem;
        font-weight: 600;
        border-radius: 5px;
        width: 100%;
    }
}