.fillpage {
  font-family: var(--Primary-font);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  padding-bottom: 1rem;

}

.fillpage1 {
  width: 80%;
  height: 100%;

}

.edu-image {
  width: 100%;
  height: 70vh;
}

.edu-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.educnt {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  padding-bottom: 20px;
}

.educnt img {
  width: 100%;
  border-radius: 15px;
}

.educnt .kulamlogo {
  width: 100%;
  border-radius: 15px;
  height: 20rem;
  object-fit: cover;
}

.eduinfo {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  width: 100%;


}

.educnt h3 {
  font-size: 20px;
  padding-top: 10px;
}

.educnt p {
  font-size: 12px;
  font-weight: bold;
  color:
    #9B9A9B;
}

.eduinfo h3 {
  font-size: 20px;
  padding-bottom: 15px;
}

.eduinfo p {
  line-height: 25px;
  font-weight: bold;
  color:
    #9B9A9B;
  font-size: 16px;
  padding-bottom: 10px;
  text-align: justify;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 16rem;
  object-fit: cover;
}

.coverimaghome {
  display: block;
  width: 100%;
  height: 33rem !important;
  object-fit: cover;
  border-radius: 10px;
}

.reactplayerdiv {
  width: 100%;
  height: 16rem;
  object-fit: cover;
  border-radius: 10px;
}

.education-slider {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.edu-gallery {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;


}

.edu-gallery h1 {
  font-size: 20px;
  padding: 20px 0px;
}

.edu-gallery1 {
  width: 100%;
  height: 100%;

}

.edu-slider {
  width: 300px;
  height: 300px;
  border-radius: 10px;
}

.edu-slider img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.educations-swipers2 {
  display: none;
}

.css-13cymwt-control {
  box-shadow: none;
}

@media screen and (max-width:600px) {
  .fillpage {
    font-family: var(--Primary-font);
    width: auto;
    padding: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    padding-bottom: 1rem;

  }

  .fillpage1 {
    width: 100%;
    height: 100%;


  }

  .edu-image {
    width: 100%;
    height: auto;
  }

  .edu-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .educnt {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 25px;
    padding-bottom: 20px;

  }

  .educnt img {
    width: 100%;
    border-radius: 15px;
  }

  .eduinfo {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    width: 100%;


  }

  .educnt h3 {
    font-size: 20px;
    padding-top: 10px;
  }

  .educnt p {
    font-size: 12px;
    font-weight: bold;
    color:
      #9B9A9B;
  }

  .eduinfo h3 {
    font-size: 20px;
    padding-bottom: 15px;
  }

  .eduinfo p {
    line-height: 25px;
    font-weight: bold;
    color:
      #9B9A9B;
    font-size: 16px;
    padding-bottom: 10px;
  }

  .swiper {
    width: 100%;
    height: 100%;
    /* z-index: -1 !important; */
  }

  .education-swipers1 {
    display: none;
  }

  .educations-swipers2 {
    display: block;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 13rem;
    object-fit: cover;
  }

  .education-slider {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;


  }

  .edu-gallery {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;


  }

  .edu-gallery h1 {
    font-size: 20px;
    padding: 20px 0px;
  }

  .edu-gallery1 {
    width: 100%;
    height: 100%;

  }

  .edu-slider {
    width: 300px;
    height: 300px;
    border-radius: 10px;
  }

  .edu-slider img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}