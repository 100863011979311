.fulldiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;
    margin-bottom: 2rem;
    height: 100vh;

}

.newscontent {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0rem;
}

.newsimage {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}

.newsimage::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
    height: 50px;
}

.newscroll {
    width: 55%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;


}

.newscroll h1 {
    color: var(--primary-color);
    margin-left: 20px;
    /* padding: 10px; */
    font-size: 20px;
    border-bottom: 1px solid grey;
    text-align: start;

}

.newsimage img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    padding-right: 6px;
}

.newscont {
    text-align: center;
    line-height: 25px;
    padding: 10px 0px;

}

.newscont p {
    font-size: 16px;
    font-family: var(--primary-font);
    font-weight: bold;
    color: grey;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    text-align: justify;
}

.picscroll {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    overflow-y: scroll;
    padding: 10px 0px;
    margin-left: 20px;
    cursor: pointer;
}

.picscroll::-webkit-scrollbar {
    display: none;
}

.newsimgscroll {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 0px;
    margin-bottom: 20px;
    transition: .5s;
    border: 1px solid gray;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.newsimgscroll img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 5px;
}

.newsimgscroll:hover {
    background-color: grey;
}

.newsimgscroll:hover .newsdetail h3 {
    color: white;
}

.newsimgscroll:hover .newsdetail p {
    color: white;
}

.newsdetail {
    padding-left: 20px;
    line-height: 25px;
}

.newsdetail h3 {
    font-size: 16px;
    transition: .5s;
    text-align: start;
}

.newsdetail p {
    font-size: 12px;
    transition: .5s;
    text-align: start;

}


.newsscont {
    text-align: center;
    line-height: 25px;
    padding: 10px;
}

.newsscont h1 {
    font-size: 20px;
    padding-bottom: 10px;
}

.newsscont p[name] {
    text-align: start;
}

.newss1 .newsscont {

    text-align: start;
    padding-top: 20px;
}

.newss1 .newsscont h1 {
    font-size: 20px;
}

.newsscont p {
    font-size: 16px;
    font-family: var(--primary-font);
    font-weight: bold;
    color: grey;
    padding: 5px 0px;
}

.newss1 p {
    font-size: 16px;
    color: gray;
    line-height: 25px;
    text-align: start;
    font-weight: bold;
    padding-top: 20px;
}

.activenewsscroll {
    background-color: gray;
    color: white;
}

.newstitle {
    text-align: left;
}

/* .newscont::-webkit-scrollbar {
    display: block;
} */
/* 
@media screen and (max-width:768px) {
    .newscontent {
        display: flow;
        width: 90%;
    }

    .newsimage img {
        width: 100%;
        height: 25rem;
        object-fit: cover;
        border-radius: 10px;
    }

    .newscont {
        text-align: center;
        line-height: 25px;
        padding: 0px;
        height: 400px;
        overflow: scroll;
    }

    .newscroll h1 {
        margin: 0;
    }

    .picscroll {
        margin-top: 1rem;
        margin-left: 0;

    }
}



.picscroll::-webkit-scrollbar {
    display: none;
} */
@media screen and (max-width:768px) {
    .fulldiv {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0rem;
        margin-bottom: 2rem;
        padding: 20px 20px;

    }

    .newscontent {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px;


    }

    .newscont h3 {
        padding: 10px 0px;
        width: 100%;
    }

    .newsimage {
        width: 100%;
        height: auto;
        overflow-y: scroll;
    }

    .newsimage::-webkit-scrollbar {
        display: none;
    }

    .newscroll {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        display: block;
        padding: 20px 0px;

    }

    .newscroll h1 {
        color: var(--primary-color);
        margin-left: 0px;
        /* padding: 10px; */
        font-size: 20px;
        border-bottom: 1px solid grey;
        text-align: start;
        width: 130px;
    }

    .newsimage img {
        width: 100%;
        height: 60%;
        object-fit: cover;
        padding: 0px;
    }

    .newscont {
        text-align: center;
        line-height: 25px;
        padding: 0px;

    }

    .newscont p {
        font-size: 16px;
        font-family: var(--primary-font);
        font-weight: bold;
        color: grey;
        padding: 0px;
    }

    .picscroll {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 10px;
        overflow-y: scroll;
        padding: 10px 0px;
        margin-left: 0px;

    }

    .picscroll::-webkit-scrollbar {
        display: none;
    }

    .newsimgscroll {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        border-radius: 0px;
        margin-bottom: 20px;
        transition: .5s;
        border: 1px solid gray;

        border-radius: 10px;
    }

    .newsimgscroll img {
        width: 80px;
    }

    .newsl .newsimgscroll:hover {
        background-color: grey;
    }

    .newsimgscroll:hover .newsdetail h3 {
        color: white;
    }

    .newsimgscroll:hover .newsdetail p {
        color: white;
    }

    .newsdetail {
        padding-left: 20px;
        line-height: 25px;
    }

    .newsdetail h3 {
        font-size: 16px;
        transition: .5s;
        text-align: start;
    }

    .newsdetail p {
        font-size: 12px;
        transition: .5s;
    }


    .newsscont {
        text-align: center;
        line-height: 25px;
        padding: 10px;
    }

    .newsscont h1 {
        font-size: 20px;
        padding-bottom: 10px;
    }

    .newsscont p[name] {
        text-align: start;
    }

    .newss1 .newsscont {

        text-align: start;
        padding-top: 20px;
    }

    .newss1 .newsscont h1 {
        font-size: 20px;
    }

    .newsscont p {
        font-size: 16px;
        font-family: var(--primary-font);
        font-weight: bold;
        color: grey;
        padding: 5px 0px;
    }

    .newss1 p {
        font-size: 16px;
        color: gray;
        line-height: 25px;
        text-align: start;
        font-weight: bold;
        padding-top: 20px;
    }

    .newsslider1 .imm img {
        width: 90px;
    }
}