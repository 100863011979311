
 :root{
  --Primary-font:'Quicksand', sans-serif;
}
.homeswiper{
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0rem;
  z-index: -1;
  margin-bottom: 20px;
  
}
.homeswiper1{
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 

}
.homeswiper2{
  width: 100%;
  height: 100%;
  

}
.slider .swiper-slide img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.swiper {
    width: 100%;
    height: 100%;
  }

  .homeswiper2 .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 768px){
    :root{
      --Primary-font:'Quicksand', sans-serif;
    }
    .homeswiper{
      width: auto;
      height:35%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0rem;
      z-index: -1;
      
      padding: 0px;
      
    }
    .homeswiper1{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
     
    
    }
    .homeswiper2{
      width: 100%;
      height: 100%;
      
    
    }
    .slider img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    .swiper {
        width: 100%;
        height: 100%;
      }
    
      .swiper-slide {
        text-align: center;
        font-size: 18px;
      
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    
  }
  

