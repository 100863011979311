/* .Trade-head {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trade-head1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 20px 0px;
}

.trade-head1 h1 {
    font-size: 25px;
    padding: 20px 0px;

    color: var(--primary-color)
}

.trade-head1 p {
    line-height: 25px;
    text-align: center;
    width: 650px;
    font-weight: bold;

}

.trade-search {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;


}

.trade-search1 {
    border-radius: 15px;
    background-color: whitesmoke;
    width: 80%;
    height: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    flex-direction: column;
}

.trade-search1 p {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;

}

.search-scroll {
    width: 100%;

    padding-top: 0px;
}

.trade-search1 p[name] {
    width: 100%;
    height: 50px;
    background-color: whitesmoke;
    border-bottom: 1px solid gray;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: gray;

}

.search-scroll p .search-scroll-icon {
    font-size: 20px;
}

.trade-search1 input {
    width: 95%;
    height: 100%;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 16px;
    font-family: var(--Primary-font);
    background-color: whitesmoke;

    margin-left: 10px;
}

.trade-search1 p .trade-icon {
    font-size: 20px;

    width: auto;
    height: auto
}

.member-content {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0px;
    margin-bottom: 3rem;

}

.member-contents1 {
    width: 80%;
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.member-cards {
    width: 100%;
    height: 100%;
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 30px;
}

.member-card1 {
    width: 100%;
    height: 120px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    border: 1px solid gray;
    border-radius: 10px;
}

.member-box1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.member-img-con {
    width: 100%;
    padding-left: 20px;
}

.member-img {
    width: 150px;
    height: 100%;
    object-fit: cover;
}

.member-img img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.member-contact {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.member-img-con h2 {
    font-size: 18px;
    padding-bottom: 5px;
}

.member-img-con p {
    font-size: 14px;
    color: gray;
}

.member-contact p {
    font-size: 16px;
    padding-bottom: 5px;
    font-weight: bold;
}

.member-contact p .member-i {
    font-size: 16px;
    margin-right: 10px;

}

.member-contact p a {
    color: black;
}

.member-contents1 h1 {
    font-size: 20px;
}


@media screen and (max-width:600px) {
    .Trade-head {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;

    }

    .trade-head1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 0px;
    }

    .trade-head1 h1 {
        font-size: 25px;
        padding: 0px 0px;
        padding-bottom: 8px;
        color: var(--primary-color)
    }

    .trade-head1 p {
        line-height: 25px;
        text-align: center;
        width: 100%;
        font-weight: bold;

    }

    .trade-search {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;



    }

    .trade-search1 {
        border-radius: 15px;
        background-color: whitesmoke;
        width: 100%;
        height: 100%;
        padding: 20px 0px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px;
        flex-direction: column;
        margin-top: 10px;

    }

    .trade-search1 p {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .search-scroll {
        width: 100%;

        padding-top: 0px;
    }

    .trade-search1 p[name] {
        width: 100%;
        height: 50px;
        background-color: whitesmoke;
        border-bottom: 1px solid gray;
        margin: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px;
        color: gray;

    }

    .search-scroll p .search-scroll-icon {
        font-size: 20px;
    }

    .trade-search1 input {
        width: 95%;
        height: 100%;
        border: none;
        outline: none;
        padding: 5px 0px;
        font-size: 16px;
        font-family: var(--Primary-font);
        background-color: whitesmoke;

        margin-left: 0px;
    }

    .trade-search1 p .trade-icon {
        font-size: 20px;

        width: auto;
        height: auto
    }

    .member-content {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 25px 0px;
        margin-bottom: 3rem;
        padding: 0px 20px;

    }

    .member-contents1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }

    .member-cards {
        width: 100%;
        height: 100%;
        padding-top: 40px;
        display: grid;
        grid-template-columns: repeat(1, 4fr);
        grid-gap: 30px;

    }

    .member-card1 {
        width: 100%;
        height: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        border: 1px solid gray;
        border-radius: 10px;

    }

    .member-box1 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

    }

    .member-img-con {
        width: 100%;
        padding-left: 10px;
    }

    .member-img {
        width: 70px;
        height: 100%;
        object-fit: cover;
    }

    .member-img img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }

    .member-contact {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

    }

    .member-img-con h2 {
        font-size: 16px;
        padding-bottom: 5px;

    }

    .member-img-con p {
        font-size: 12px;
        color: gray;
    }

    .member-contact p {
        font-size: 12px;
        padding-bottom: 5px;
        font-weight: bold;
    }

    .member-contact p .member-i {
        font-size: 12px;
        margin-right: 5px;

    }

    .member-contact p a {
        color: black;
    }

    .member-contents1 h1 {
        font-size: 16px;

    }

}

.listtag {
    margin-bottom: 2rem;
} */





.Trade-head {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

}

.trade-head1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 20px 0px;
}

.trade-head1 h1 {
    font-size: 25px;
    padding: 20px 0px;

    color: var(--primary-color)
}

.trade-head1 p {
    line-height: 25px;
    text-align: center;
    width: 650px;
    font-weight: bold;

}

.trade-search {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;


}

.trade-search1 {
    border-radius: 15px;
    background-color: whitesmoke;
    width: 80%;
    height: 100%;
    padding: 20px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    flex-direction: column;
}

.trade-search1 p {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;

}

.search-scroll {
    width: 100%;

    padding-top: 0px;
}

.trade-search1 p[name] {
    width: 100%;
    height: 50px;
    background-color: whitesmoke;
    border-bottom: 1px solid gray;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: gray;

}

.search-scroll p .search-scroll-icon {
    font-size: 20px;
}

.trade-search1 input {
    width: 95%;
    height: 100%;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 16px;
    font-family: var(--Primary-font);
    background-color: whitesmoke;

    margin-left: 10px;
}

.trade-search1 p .trade-icon {
    font-size: 20px;

    width: auto;
    height: auto
}

.member-content {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0px;
    margin-bottom: 3rem;



}

.member-contents1 {
    width: 80%;
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.member-cards {
    width: 100%;
    height: 100%;
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 30px;


}

.member-card1 {
    width: 100%;
    height: 120px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    border: 1px solid gray;
    border-radius: 10px;
}

.member-box1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.member-img-con {
    width: 100%;
    padding-left: 20px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

}

.member-img {
    width: 150px;
    height: 100%;
    object-fit: cover;
}

.member-img img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.member-contact {

    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.member-img-con h2 {
    font-size: 18px;
    padding-bottom: 0px;
    text-align: left;
}

.member-img-con p {
    font-size: 14px;
    color: gray;
    text-transform: capitalize;
}

.member-contact p {
    font-size: 16px;
    padding-bottom: 5px;
    font-weight: bold;
    text-transform: lowercase;
}

.member-contact p .member-i {
    font-size: 16px;
    margin-right: 10px;
    text-transform: lowercase;

}

.member-contact p a {
    color: black;
}

.member-contents1 h1 {
    font-size: 20px;
}


@media screen and (max-width:768px) {
    .Trade-head {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;

    }

    .trade-head1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 0px;
    }

    .trade-head1 h1 {
        font-size: 25px;
        padding: 0px 0px;
        padding-bottom: 8px;
        color: var(--primary-color)
    }

    .trade-head1 p {
        line-height: 25px;
        text-align: center;
        width: 100%;
        font-weight: bold;

    }

    .trade-search {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;



    }

    .trade-search1 {
        border-radius: 15px;
        background-color: whitesmoke;
        width: 100%;
        height: 100%;
        padding: 20px 0px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px;
        flex-direction: column;
        margin-top: 10px;

    }

    .trade-search1 p {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .search-scroll {
        width: 100%;

        padding-top: 0px;
    }

    .trade-search1 p[name] {
        width: 100%;
        height: 50px;
        background-color: whitesmoke;
        border-bottom: 1px solid gray;
        margin: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px;
        color: gray;

    }

    .search-scroll p .search-scroll-icon {
        font-size: 20px;
    }

    .trade-search1 input {
        width: 95%;
        height: 100%;
        border: none;
        outline: none;
        padding: 5px 0px;
        font-size: 16px;
        font-family: var(--Primary-font);
        background-color: whitesmoke;

        margin-left: 0px;
    }

    .trade-search1 p .trade-icon {
        font-size: 20px;

        width: auto;
        height: auto
    }

    .member-content {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 25px 0px;
        margin-bottom: 3rem;
        padding: 0px 20px;

    }

    .member-contents1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

        overflow-x: scroll;
    }

    .member-contents1 table {
        width: 100%;
    }

    /* .member-contents1 table th,tr,td{
        width:150px;
        border: 2px solid red;
    } */
    .member-cards {
        width: 100%;
        height: 100%;
        padding-top: 40px;
        display: grid;
        grid-template-columns: repeat(1, 4fr);
        grid-gap: 30px;
        overflow-x: hidden;

    }

    .member-card1 {
        width: 100%;
        height: auto;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        border: 1px solid gray;
        border-radius: 10px;

    }

    .member-box1 {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

    }

    .member-img-con {
        width: 100%;
        padding-left: 10px;
    }

    .member-img {
        width: 70px;
        height: 100%;
        object-fit: cover;
    }

    .member-img img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }

    .member-contact {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

    }

    .member-img-con h2 {
        font-size: 16px;
        padding-bottom: 5px;
        text-align: left;
    }

    .member-img-con p {
        font-size: 12px;
        color: gray;
        text-transform: lowercase;
    }

    .member-contact p {
        font-size: 12px;
        padding-bottom: 5px;
        font-weight: bold;
        text-transform: lowercase;
    }

    .member-contact p .member-i {
        font-size: 12px;
        margin-right: 5px;
        text-transform: lowercase;

    }

    .member-contact p a {
        color: black;
    }

    .member-contents1 h1 {
        font-size: 16px;

    }

}