.mem-detail {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    justify-content: center;

}

.familyprofileimg {
    height: 5rem;
    object-fit: cover;
    width: 5rem;
}

.mem-detail1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mem-detail1 h1 {
    padding-bottom: 20px;
    font-size: 20px;
    color: rgba(209, 17, 43, 1)
}

.mem-detail1 p {
    text-align: center;
    width: 600px;
    line-height: 25px;
}

.mem-detail2 {
    width: 100%;
    /* height: 80%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem 0rem;
}

.mem-detail13 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.mem-detail13 .mem-images {
    width: 100%;
    height: 100%;

}

.mem-detail13 .mem-table {
    width: 100%;
    height: 100%;
    margin-left: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.mem-table {
    margin-top: 2rem;
}

.mem-images1 {
    width: 100%;
    height: 80%;
    border-radius: 10px;

}

.mem-images1 img {
    width: 100%;
    height: 40rem;
    object-fit: cover;
    border-radius: 10px;
}

.familyhead {
    color: rgba(209, 17, 43, 1);
}

.mem-contents {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mem-contents h1 {
    font-size: 20px;
    padding: 10px;
}

.mem-contents h3 {
    font-size: 16px;
    color: gray
}

.table {
    border-radius: 10px;
}

.table,
thead,
tr,
th,
td {
    border: 1px solid lightgray;
    width: 200px;
    height: 45px;
    text-align: center;
    border-radius: 10px;
}


td button {
    padding: 6px 15px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 8px;
}

@media screen and (max-width:768px) {
    .mem-detail {
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 20px;
        justify-content: center;


    }

    .mem-detail1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .mem-detail1 h1 {
        padding-bottom: 20px;
        font-size: 20px;
        color: rgba(209, 17, 43, 1)
    }

    .mem-detail1 p {
        text-align: center;
        width: 100%;
        line-height: 25px;
    }

    .mem-detail2 {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem 0rem;
        padding: 0px 20px;

    }

    .mem-detail13 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

    }

    .mem-detail13 .mem-images {
        width: 100%;
        height: 100%;
    }

    .mem-detail13 .mem-table {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 20px;

        padding: 0px;
        margin: 15px 0px;
    }

    .mem-images1 {
        width: 100%;
        height: 70%;

    }

    .mem-images1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .mem-contents {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .mem-contents h1 {
        font-size: 20px;
        padding: 10px;
    }

    .mem-contents h3 {
        font-size: 16px;
        color: gray
    }

    .table,
    thead,
    tr,
    th,
    td {
        border: 1px solid #333333;
        width: 200px;
        height: 45px;
        text-align: center;
        color: black;
    }

    td button {
        padding: 6px 15px;
        background-color: black;
        color: white;
        border: none;
        border-radius: 8px;
    }
}