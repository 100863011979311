:root {
    --Primary-font: 'Quicksand', sans-serif;
}

.footer {
    width: 100%;
    height: auto;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(51, 51, 51, 1);

}

.footer1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: rgba(51, 51, 51, 1);
}

.footer2 {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
}

.footer2 img {
    width: 140px;
    -webkit-text-fill-color: white;

}

.footer2 p {
    padding: 3px;
    font-size: 16px;
    font-weight: bold;
    color: white
}

.footer3 {
    width: auto;
    color: white;
    height: auto;
}

.footer3 h1 {
    font-size: 18px;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 10px;
    padding-bottom: 4px;
}

.footer31 p {
    text-align: start;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
}

.footer4 {
    width: 235px;
    height: auto;
    color: white;

}

.footer4 h1 {
    width: 100px;
    font-size: 18px;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 10px;
    padding-bottom: 4px;
}

.contactinfo {
    text-align: left;
    text-transform: lowercase;
    font-size: 20px;
}

.contactinfonew {
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px;
}

.infologo {
    color: black;
    font-size: 20px;
}

.contactinfodiv {
    margin-top: 10px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
}

.contactinfodivnew {
    margin-top: 10px;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
}

.newdiv {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.footer4 p {
    font-size: 14px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    text-transform: lowercase;
}

.footer4 p[name] {
    padding-left: 20px;
    font-size: 14px;
    line-height: 30px;
}

.footer4 p .foot-icon {
    margin-right: 8px;
    color: white;
    text-align: start;
}

.footer4 p .foot-icon2 {
    margin-right: 8px;
    color: white;
}

.foot-icon1 {
    padding: 10px 0px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.foot-icon2 {
    color: white;
    font-size: 15px;
    margin-right: 20px;
}

.copyright {
    width: 100%;
    height: auto;
    padding: 20px 0px;
    text-align: center;
}

.copyright h1 {
    font-size: 16px;
}

@media (max-width: 768px) {
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: auto;
        /* background-color: red; */
        padding: 20px;
    }

    .footer1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .footer2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .footer2 img {
        width: 83px;
        height: 83px;
    }

    .footer3 {
        display: none;
    }

    .footer4 {
        width: 100%;

        line-height: 25px;
    }

    .footer4 p {
        font-size: 18px;
        padding-bottom: 5px;
    }

    .newdiv {
        display: none;
    }

    .foot-icon2 {
        font-size: 18px;
    }

    .copyright {
        width: auto;
        padding: 20px;
    }

    .copyright h1 {
        font-size: 10px;
    }
}