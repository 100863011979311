.profile {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0rem;
    margin-bottom: 3rem;
}

.profile1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

}

.family-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 20px 0px;
}

.family-title h1 {
    font-size: 20px;
    color: gray;
    color: #D1112B;

}

.profile-img {
    width: 100%;
    height: 603px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: scroll;
}

.profile-img::-webkit-scrollbar {
    display: none;
}

.profile-img1 {
    width: 100%;
    height: 100%;
}

.profile-img1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pro-con1 {
    width: 100%;
    text-align: center;

}

.pro-con1 h1 {
    font-size: 20px;
    padding-top: 15px;

}

.pro-con1 h6 {
    font-size: 15px;
    color: gray;
    padding-top: 10px;
}

.profile-content {
    width: 100%;
    height: auto;
    padding-left: 80px;
    display: flex;

    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* background-color: red; */
}

.profile-inputs {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.profile-inputs p {
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    margin: 0.7rem 0rem;
}

.profile-inputs p input {
    width: 95%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: bold;
    font-family: var(--Primary-font);
    text-transform: lowercase;
}

.profile-inputs p textarea {
    width: 95%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: bold;
    font-family: var(--Primary-font);
    text-transform: lowercase;
}

.profile-slider {
    width: 100%;
    height: 220px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: scroll;
    top: 0;
}

.profile-slider::-webkit-scrollbar {
    display: none;
}

.profilr-slide {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid gray;
    padding: 10px 0px;
}

.pro-con {
    padding-left: 15px;
}

.pro-con h1 {
    font-size: 16px;
}

.pro-con h6 {
    font-size: 12px;
    padding: 5px 0px;
    text-align: left;
}

.profile_div {
    display: flex;
    gap: 8rem;
    padding-left: 10rem;
    padding-right: 10rem;
    justify-content: space-between;
}

.profilfamilydiv {
    border: 1px solid gray;
}

.profil_familydiv {
    padding: 10px;
    /* border: 1px solid gray; */
}

.familyname {
    color: black;
    font-size: 15px !important;
}

.familyprofileimg {
    height: 5rem !important;
    width: 5rem !important;
    object-fit: cover !important;
    border-radius: 5px;
}

.profile_div1 {
    width: 50%;
    height: 100%;
}

.profileimg {
    height: 8rem;
    border-radius: 10px;
}

.profilevalue {
    color: gray;
    text-transform: math-auto;
}

.newprofiletable {
    width: 40%;
    margin-bottom: 20px;
    margin-top: 0 !important;
}

.viewprofilenew {
    font-size: 15px !important;
    text-decoration: underline;
    color: #01ACEF !important;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: end;
    cursor: pointer;
}

.profiledata {
    display: flex;
    gap: 2rem;
    margin-top: 10px;
    margin-bottom: 20px;
}

@media(max-width:600px) {
    .profile {
        width: auto;
        padding: 20px;
        display: flex;
        align-items: center;
    }

    .profile1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .profile-content {
        width: 100%;
        height: 100%;
        padding: 0;
        margin-top: 10px;
    }

    .profile_div {
        display: contents;
        gap: 8rem;
        padding-left: 1rem;
        padding-right: 1rem;
        justify-content: space-between;
    }

    .profile_div1 {
        width: 100%;
        height: auto;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .profilehead {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 2px solid gray;
        padding-bottom: 1rem;
        margin-top: 10px;
        padding-left: 1rem;
        padding-right: 1rem;
        align-items: center;
    }

    .family_name_head {
        color: #D1112B !important;
        font-size: 15px;
    }

    .newprofiletable {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0 !important;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .profile-img {
        width: 100%;
        height: auto;
    }
}

.profilehead {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px solid gray;
    padding-bottom: 1rem;
    margin-top: 10px;
}

.addfamilybtn {
    background-color: black;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
}

.family_name_head {
    color: #D1112B !important;
}

.labeltag {
    margin-bottom: 10px;
    font-size: 15px;
    margin-left: 5px;
}

.swiper {
    z-index: 0 !important;
}

.profileheadfamily {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
    border-bottom: 2px solid gray;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.editicon {
    cursor: pointer;
    color: black;
}
.editicondelete{
    cursor: pointer;
    color: red;
}

.profilepicimgnew {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid black;
    margin: 1rem;
}