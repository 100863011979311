:root {
    --Primary-font: 'Quicksand', sans-serif;
}

.home-leader {
    width: 100%;
    height: auto;
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.home-leader1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-leader2 {
    width: 55%;
    height: 50%;
    text-align: center;
}

.home-leader2 h1 {
    font-size: 20px;
    padding-bottom: 10px;
    color: var(--primary-color);
}

.home-leader2 p {
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
}

.home-scroll {
    width: 100%;
    height: auto;
    background-color: rgba(51, 51, 51, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.home-scrolloffice {
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    margin-top: 3rem;
}

.home-scrollofficenew {
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}

.officename {
    color: white;
}

.home-scroll1 {
    width: 80%;
    height: auto;

}

.chairman-contentoffice {
    margin-top: 10px;
    padding-bottom: 20px;
}

.roletag {
    color: gray;
    margin-top: 10px;
}

.home-scroll3 {
    display: none;
}

.home-scroll3 .sscroll {
    width: auto;
    height: auto;
    background-color: #333333;
    display: flex;
    align-items: center;
    flex-direction: column;


}

.home-scroll1 .sscroll {
    width: auto;
    height: auto;
    background-color: #333333;
    display: flex;
    align-items: center;
    flex-direction: column;


}

.sscroll img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.sscroll .his-slide1 {
    text-align: center;
    margin-bottom: 20px;
}

.his-slide1 h1 {
    font-size: 15px;
    color: white;
    padding: 20px 0px;
}

.his-slide1 p {
    font-size: 15px;
    line-height: 25px;
    padding-bottom: 20px;
    color: white;
}

.his-slide1 h6 {
    padding: 5px 10px;
    border-radius: 10px;
    border: none;
    font-size: 15px;
    background-color: rgba(209, 17, 43, 1);
    width: 140px;
    text-align: center;
    color: white;
    margin: 0 auto;
    cursor: pointer;
}

.homeleaderimg {
    height: 33rem !important;
}

.homeleaderimgoffice {
    height: 27rem;
}

@media screen and (max-width:768px) {
    :root {
        --Primary-font: 'Quicksand', sans-serif;
    }

    .home-scrolloffice {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .home-leader {
        width: 100%;
        height: auto;
        padding-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0px 20px;

    }

    .home-leader1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .home-leader2 {
        width: 100%;
        height: 50%;
        text-align: center;

    }

    .home-leader2 h1 {
        font-size: 20px;
        padding-bottom: 10px;
        color: var(--primary-color);
    }

    .home-leader2 p {
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
    }

    .home-scroll {
        width: 100%;
        height: auto;
        background-color: rgba(51, 51, 51, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 20px;
        margin-top: 1rem;



    }

    .home-scroll1 {
        width: 100%;
        height: auto;


    }

    .sscroll {
        width: 100%;
        height: auto;
        background-color: rgba(51, 51, 51, 1);
        ;
        display: flex;
        align-items: center;
        flex-direction: column;



    }

    .home-scroll2 {
        display: none;
    }

    .sscroll img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    .sscroll .his-slide1 {
        text-align: center;
        margin-bottom: 20px;

    }

    .his-slide1 h1 {
        font-size: 15px;
        color: white;
        padding: 20px 0px;
    }

    .his-slide1 p {
        font-size: 15px;
        line-height: 25px;
        padding-bottom: 20px;
        color: white;
    }

    .his-slide1 h6 {
        padding: 5px 10px;
        border-radius: 10px;
        border: none;
        font-size: 15px;
        background-color: rgba(209, 17, 43, 1);
        width: 140px;
        text-align: center;
        color: white;
        margin: 0 auto;
        cursor: pointer;
    }

    .home-scroll3 {
        display: block;
    }

    .swiper-slide {
        background-color: rgba(51, 51, 51, 1);
        ;
    }
}