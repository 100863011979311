.company-details {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company-details1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.company-details2 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.company-details2 .company-det-images {
    width: 100%;
    height: 80%;
}

.company-det-images img {
    width: 100%;
    height: 35rem;
    object-fit: cover;
    border-radius: 10px;
}

.company-details2 .com-contents {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.com-contents h1 {
    font-size: 20px;
    padding: 8px;
}

.company-contents {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 25px;
    margin-top: 25px;
}

.company-contents h1 {
    font-size: 20px;
    padding: 10px 0px;
}

.company-contents p {
    line-height: 25px;
    font-size: 16px;
    color: gray;

    text-align: justify;
}

.companydiv {
    padding-left: 12rem;
    padding-right: 12rem;
}

.coverimg {
    width: 100%;
    margin-top: 10px;
}

.coverimgcompany {
    width: 100%;
    margin-top: 10px;
    height: 25rem;
    object-fit: cover;
    border-radius: 10px;
}

.title {
    font-weight: bold;
    text-align: left;
    margin-top: 20px;
}

.location {
    color: gray;
    text-align: left;
    font-size: 20px;
    margin-top: 10px;
}

.description {
    color: gray;
    text-align: left;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.companynewdiv {
    display: flex;
    gap: 2rem;
    /* align-items: center; */
}

.companydes {
    width: 70%;
}

.contacttag {
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    margin-bottom: 5px;
}

.contatable {
    margin-top: 3rem;
}

.profilediv {
    margin-top: 20px;
    border: 1px solid lightgray !important;
}


.viewprofile {
    font-size: 15px !important;
    text-decoration: underline;
    color: #01ACEF !important;
    font-weight: 700;
    margin-top: 5px;
}

.companytable,
th,
td {
    border: 1px solid black;
}

@media screen and (max-width: 768px) {
    .company-details {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
    }

    .company-details1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .company-details2 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .company-details2 .company-det-images {
        width: 100%;
        height: 80%;
    }

    .companydiv {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .companynewdiv {
        display: contents;
    }

    .companydes {
        width: 100%;
        text-align: justify;
    }

    .profilediv {
        margin-bottom: 20px;
    }

    .helplineimg {
        width: 56vh;
        height: 100vh;
    }

    .company-det-images img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .company-details2 .com-contents {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .com-contents h1 {
        font-size: 20px;
        padding: 8px;
    }

    .company-contents {
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin: 20px 0px;
    }

    .company-contents h1 {
        font-size: 20px;
        padding: 10px 0px;
    }

    .company-contents p {
        line-height: 25px;
        font-size: 16px;
        color: gray;
        padding-bottom: 20px;
        text-align: justify;
    }
}