

.totaldiv {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.meetcontent {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;


}

.meetimage {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    
    
}

/* .meetimage::-webkit-scrollbar {
    display: none;
} */

.meetscroll {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    
}

.meetscroll h1 {
    font-size: 20px;
    color: var(--primary-color);
    margin-left: 20px;
    padding: 0px;
    border-bottom: 1px solid grey;

}

.meetimage img {
    width: 100%;
    height: 70%;
    object-fit: cover;
    padding-right: 6px;
}

.meetcont {
    text-align: center;
    line-height: 25px;
    padding: 10px 0px;
    background-color: white;
    padding-right: 6px;
}

.meetcont h1 {
    font-size: 20px;
    padding-bottom: 10px;
}

.meetcont p[name] {
    text-align: start;
}

.meets1 .meetcont {

    text-align: start;
    padding-top: 20px;
}

.meet1 .meetcont h1 {
    font-size: 20px;
    
}

.meetcont h3{
    text-align: start;
}
.meetcont p {
    font-size: 16px;
    font-family: var(--primary-font);
    font-weight: normal;
    color: grey;
    padding: 5px 0px;
    text-align: justify;
}

.meetingscroll {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    overflow-y: scroll;
    padding: 10px 0px;
    margin-left: 0px;
    padding-left: 20px;
    
}

.meetingscroll::-webkit-scrollbar {
    display: none;
}

.meetimgscroll {
    width: 100%;
        display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 0px;
    margin-bottom: 20px;
    transition: .5s;
    border:1px solid gray   ;
    border-radius: 10px;
}

.meetimgscroll img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 5px;
}

.meetimgscroll:hover {
    background-color: grey;
}

.meetimgscroll:hover .meetdetail h3 {
    color: white;
}

.meetimgscroll:hover .meetdetail p {
    color: white;
}

.meetdetail {
    padding-left: 20px;
    line-height: 25px;
}

.meetdetail h3 {
    font-size: 16px;
    transition: .5s;
    text-align: start;
}

.meetdetail p {
    font-size: 12px;
    transition: .5s;
    text-align: start;
}

.meets1 p {
    font-size: 16px;
    color: gray;
    line-height: 25px;
    text-align: start;
    font-weight: bold;
    padding-top: 20px;
}

.meetimage::-webkit-scrollbar{
    width: 8px;
}
::-webkit-scrollbar-track{
    background-color:rgba(0, 0, 0, 0.1);
     border-radius: 10px;
}
::-webkit-scrollbar-thumb{
    background:gray;
    border-radius: 10px;
    height: 50px;
}

@media(max-width:768px) {
    .totaldiv {
        width: auto;
        height: auto;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        

    }

    .meetcontent {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;border: none;

    }

    .meetcont {
        text-align: center;
        line-height: 25px;
        padding: 10px 0px;
        background-color:white;
        border: none;
        outline: none;
    }
    .meetimage::-webkit-scrollbar {
    display: none;
} 

    .meet-leader1 {
        width: 100% !important;
    }

    .meet-leader2 {
        width: 100% !important;
    }

    .m-scroll img {
        width: 15rem !important;
        height: 15rem !important;
        border-radius: 10px;
        object-fit: cover;
    }

    .meetingscroll {
        margin-left: 0;
    }

    .meetscroll h1 {
        margin-left: 0;
    }
    
.meetscroll {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 20px;
    display: none;

    
}

.meetscroll h1 {
    font-size: 20px;
    color: var(--primary-color);
    margin-left: 0px;
    padding: 0px;
    border-bottom: 1px solid grey;

}

.meetimage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.meetcont {
    text-align: center;
    line-height: 25px;
    padding: 0px;
    background-color: white;
}

.meetcont h1 {
    font-size: 20px;
    padding-bottom: 10px;
}

.meetcont p[name] {
    text-align: start;
}

.meets1 .meetcont {

    text-align: start;
    padding-top: 20px;
}

.mmet1 .meetcont h1 {
    font-size: 20px;
}

.meetcont p {
    font-size: 16px;
    font-family: var(--primary-font);
    font-weight: bold;
    color: grey;
    padding: 5px 0px;
    text-align: justify;
}

.meetingscroll {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
    overflow-y: scroll;
    padding: 10px 0px;
    margin-left: 0px;
    padding-left: 0px;
    
}

.meetingscroll::-webkit-scrollbar {
    display: none;
}

.meetimgscroll {
    width: 100%;
        display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-radius: 0px;
    margin-bottom: 20px;
    transition: .5s;
    border:1px solid gray   ;
    border-radius: 10px;
}

.meetimgscroll img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 5px;
}

.meetimgscroll:hover {
    background-color: grey;
}

.meetimgscroll:hover .meetdetail h3 {
    color: white;
}

.meetimgscroll:hover .meetdetail p {
    color: white;
}

.meetdetail {
    padding-left: 20px;
    line-height: 25px;
}

.meetdetail h3 {
    font-size: 16px;
    transition: .5s;
}

.meetdetail p {
    font-size: 12px;
    transition: .5s;
    text-align: left;
}

.meets1 p {
    font-size: 16px;
    color: gray;
    line-height: 25px;
    text-align: start;
    font-weight: bold;
    padding-top: 20px;
}

}