.gallery-map {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8rem 0rem;

}

.gallery-map1 {
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.map {
    width: 100%;
    height: 30rem;
    margin-top: 0rem;

}

.map-locate {
    width: 100%;
    height: 100%;
}

.map-locate iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: none;
}

.gallery-contacts {
    width: 100%;
    height: 100%;
    padding-left: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;


}

.gallery-contacts h1 {
    font-size: 20px;
}

.textcontent {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.textcontent button {
    width: 100%;
    margin-top: 25px;
    cursor: pointer;
    padding: 5px 10px;
    background-color: #000000;
    border: none;
    border-radius: 5px;
    font-family: var(--Primary-font);
    font-weight: bold;
    color: white;
    height: 35px;
}

.textcontent h1 {
    font-size: 16px;
    padding-bottom: 15px;
    margin-top: 10px;
}

.gallery-contacts textarea {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    height: 155px;
}

.gallery-contacts h1 {
    text-align: center;
}

.gallery-contacts .inputs {
    width: 100%;

    padding: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

}

.gallery-contacts .inputs input {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border: none;
    font-size: 16px;
    font-family: var(--Primary-font);
    font-weight: bold;
    outline: none;
    border-bottom: 1px solid black;
    font-family: var(--Primary-font);
}

@media screen and (max-width:768px) {
    .gallery1 p {
        font-size: 24px;
        font-family: var(--primary-font);
        line-height: 25px;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }

    .gallery1 {
        width: 90%;
    }

    .galery-slider1 {
        width: 100%;
        height: 100%;
        padding: 0rem 0rem;
    }

    .gallery-map1 {
        width: 90%;
        display: flow !important;
    }

    .map {
        width: 100%;
        height: 18rem;
    }

    .gallery-contacts {
        width: 100%;
        height: 100%;
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 2rem;

    }

    .gallery-map {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem 0rem;
        padding: 0px 20px;

    }

    .gallery-map1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
}