:root {
    --Primary-font: 'Quicksand', sans-serif;
}

.home-history {
    width: 100%;
    height: auto;
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.home-history1 {
    width: 80%;
    height: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.history-content {
    width: 65%;
    height: 100%;
    margin-right: 20px;
    padding-right: 10px;

}

.history-content h1 {
    color: var(--primary-color);
    font-size: 20px;
    text-align: start;
}

.history-content p {
    font-size: 25px;
    padding: 10px 0px;
    font-weight: bold;
    width: 100%;
    text-align: justify;
}

.history-content p[name] {
    text-align: justify;
    font-size: 15px;
    line-height: 23px;
    color: #333333;
    width: 100%;
}

.history-con {
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    font-size: 15px;
    margin: 10px 0px 0px 0px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(217, 217, 217, 0.5);
}

.history-con img {
    width: 30px
}

.history-con p[title] {
    color: #333333;
    font-size: 15px;
    width: 100%;
    padding-left: 10px;
    line-height: 27px
}

.history-images {
    width: 70%;
    height: 332px;
    border-radius: 10px;


}

.history-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

@media screen and (max-width:768px) {
    :root {
        --Primary-font: 'Quicksand', sans-serif;
    }

    .home-history {
        width: auto;
        height: auto;
        padding-top: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 20px 0px;

    }

    .home-history1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        flex-direction: column;
    }

    .history-content {
        width: 100%;
        height: 100%;
        margin-right: 0px;
        padding-right: 0px;


    }

    .history-content h1 {
        color: var(--primary-color);
        font-size: 20px;
    }

    .history-content p {
        font-size: 25px;
        padding: 10px 0px;
        font-weight: bold;
        width: 300px;
    }

    .history-content p[name] {
        text-align: justify;
        font-size: 15px;
        line-height: 23px;
        color: #333333;
        width: 100%;
    }

    .history-con {
        width: 100%;

        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        font-size: 15px;
        margin: 10px 0px 0px 0px;
        padding: 10px;
        border-radius: 10px;
        background-color: rgba(217, 217, 217, 0.5);
    }

    .history-con img {
        width: 30px;
    }

    .history-con p[title] {
        color: #333333;
        font-size: 15px;
        width: 100%;
        padding-left: 10px;
        line-height: 27px
    }

    .history-images {
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin: 20px 0px;


    }

    .history-images img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
}