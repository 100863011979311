:root {
    --Primary-font: 'Quicksand', sans-serif;
}

.home-news {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
}

.adhome {
    margin-bottom: 2rem;
}

.home-news1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-news2 {
    width: 55%;
    height: 50%;
    text-align: center;

}

.home-news h1 {
    font-size: 20px;
    padding-bottom: 10px;
    color: var(--primary-color);
}

.home-news p {
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
}

.home-news-slider {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem 0rem;
}

.home-news-slider1 {
    width: 80%;
    height: auto;
}

.home1-news {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.newsslider1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 0;
    /* justify-content: space-between; */
}

.imm {
    width: 500px;
    height: 100%;
}

.imm img {
    width: 100%;
    height: 100%;
}

.news-scroll {
    width: 55%;
    height: 100%;
    margin-left: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.news-date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.news-date h1 {
    font-size: 60px;
    padding-right: 10px;
    color: black;
}

.news-date p {
    font-size: 16px;
    font-weight: bold;
    color: rgba(155, 154, 155, 1);
    text-align: left;
}

.news-contenthome h1 {
    font-size: 20px;
    color: black;
    text-align: start;

}

.news-contenthome p {
    padding: 10px 0px;
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
    color: #333333;
}

.news-contenthome button {
    margin-top: 20px;
    padding: 10px 10px;
    border-radius: 5px;
    border: none;
    background-color: rgba(209, 17, 43, 1);
    color: white;
    width: 150px;
    cursor: pointer;
}

.newsslider1 img {
    height: 80%;
}

.news-contenthome {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

}

@media screen and (max-width:768px) {
    :root {
        --Primary-font: 'Quicksand', sans-serif;
    }

    .home-news {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3rem;

    }

    .home-news1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .home-news2 {
        width: 100%;
        height: 100%;
        text-align: center;


    }

    .home-news h1 {
        font-size: 20px;
        padding-bottom: 10px;
        color: var(--primary-color);
    }

    .home-news p {
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
    }

    .home-news-slider {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3rem 0rem;

    }

    .home-news-slider1 {
        width: 100%;
        height: auto;


    }

    .home1-news {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }

    .newsslider1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-top: 0;
        flex-direction: column;
        /* justify-content: space-between; */
    }

    .imm {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .imm img {
        width: 100%;
        height: 100%;
    }

    .news-scroll {
        width: 55%;
        height: 100%;
        margin-left: 2rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    .news-date {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .news-date h1 {
        font-size: 60px;
        padding-right: 10px;
        color: black;
    }

    .news-date p {
        font-size: 16px;
        font-weight: bold;
        color: rgba(155, 154, 155, 1);
        text-align: left;
    }

    .news-contenthome h1 {
        font-size: 20px;
        color: black;
        padding: 10px 0px;
    }

    .news-contenthome p {
        padding: 10px 0px;
        font-size: 15px;
        line-height: 25px;
        text-align: justify;
        color: #333333;
    }

    .news-contenthome button {
        margin-top: 20px;
        padding: 10px 10px;
        border-radius: 5px;
        border: none;
        background-color: rgba(209, 17, 43, 1);
        color: white;
        width: 100%;
        cursor: pointer;
    }

    .newsslider1 img {
        height: 80%;
    }

    .news-contenthome {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

    }

}