.navbar {
    width: 100%;
    /* height: 20vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    cursor: pointer;
}

:root {
    --Primary-font: 'Quicksand', sans-serif;
}

.navbar img {
    width: 80px;
    margin-bottom: 10px;
}

.navbar p {
    padding: 0px;
    font-size: 20px;
    font-weight: bold;
    color: rgba(155, 154, 155, 1);
    font-family: var(--primary-font);

}

.navbar1 {
    width: 100%;
    height: auto;
    padding: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.navbar2 {
    border-top: 1px solid gray;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    font-weight: bold;
    padding: 15px 0px;
}

.navbar2 li:hover {
    background-color: #333333;
    color: white;
}

.navbar2 li[name] {
    position: relative;
}

.navbar2 li[name] .down {
    width: 225px;
    height: auto;
    position: absolute;
    top: 30px;
    border-radius: 10px;
    opacity: 0;
    transition: 0.5s;
    z-index: -1;
    background-image: url(../images/bg\ 1.jpg);
    background-size: cover;
    background-position: center;
    font-family: var(--primary-font);
    padding: 20px;
    top: 50px;
    text-align: left;
    margin-left: -10px;
}

.active {
    background-color: #333333;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;

}

.navbarflex {
    display: flex;
    width: 100%;
    padding-left: 9rem;
    gap: 1rem;
    padding-right: 9rem;
    margin-top: 35px;
    margin-bottom: 10px;
}


.navbar2 li[name]:hover .down {
    opacity: 1;
    z-index: 9999;
}

.navbar2 li {
    padding: 5px 10px;
    transition: 0.5s;
    border-radius: 5px;
    cursor: pointer;
}

.down p {
    line-height: 30px;
    color: #333333 !important;
}

.mobile-nav {
    display: none;
}

.topbar {
    margin: 20px 0px 0px 0px;
    padding-left: 12rem;
    padding-right: 12rem;
    display: flex;
    gap: 3rem;
}

.emergencytag {
    color: #D1112B;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width:600px) {
    .navbar {
        width: 100%;
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 0px;
        display: none;
    }

    .emergencytag {
        color: #D1112B;
        text-decoration: underline;
        cursor: pointer;
        padding: 0.7rem;
    }

    .topbar {
        display: none;
    }

    .mobile-nav {
        display: block;
        z-index: 999;
    }

    .mobile-nav {
        width: 100%;
        height: auto;
        padding: 20px;
        /* position: relative;
        z-index: 999; */
    }

    .mobile-nav .mobile-icon {
        font-size: 30px;
        position: absolute;
        left: 20px;
    }

    .mobile-nav input {
        display: none;
    }

    :root {
        --Primary-font: 'Quicksand', sans-serif;
    }

    .navbar img {
        width: 60px;
        margin-bottom: 10px;
    }

    .navbar p {
        padding: 0px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(155, 154, 155, 1);
        font-family: var(--primary-font);

    }

    .navbar1 {
        width: 100%;
        height: auto;
        padding: 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
    }

    .navbar2 {
        border-top: 1px solid gray;
        width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        font-weight: bold;
        padding: 15px 0px;
    }

    .navbar2 li:hover {
        background-color: #333333;
        color: white;
    }

    .navbar2 li[name] {
        position: relative;
    }

    .navbar2 li[name] .down {
        width: 200px;
        height: auto;
        position: absolute;
        top: 30px;
        border-radius: 10px;
        opacity: 0;
        transition: 0.5s;
        z-index: -1;
        background-image: url(../images/bg\ 1.jpg);
        background-size: cover;
        background-position: center;
        font-family: var(--primary-font);
        padding: 20px;
        font-size: 12px;
        top: 50px;
    }

    .navbar2 li[name]:hover .down {
        opacity: 1;
        z-index: 9999;
    }

    .navbar2 li[title] {
        position: relative;
    }

    .mobile-scroll .down1 {
        opacity: 0;
    }

    .mobile-scroll li[title] .down1 {
        width: 200px;
        height: auto;
        position: absolute;
        top: 30px;
        border-radius: 10px;
        opacity: 0;
        transition: 0.5s;
        z-index: -1;
        background-image: url(../images/bg\ 1.jpg);
        background-size: cover;
        background-position: center;
        font-family: var(--primary-font);
        padding: 20px;
        color: #333333 !important;
        font-size: 18px;
        top: 150px;
        left: 20px;
    }

    .mobile-scrollli li[title] .down1 p {
        color: #333333 !important;
    }

    .mobile-scroll li[title]:hover .down1 {
        opacity: 1;
        z-index: 9999;
    }

    .active {
        background-color: #333333;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;

    }



    .navbar2 li {
        padding: 5px 10px;
        transition: 0.5s;
        border-radius: 5px;
        cursor: pointer;
    }

    .down p {
        line-height: 30px;
        color: #333333 !important;
    }

    .mobile-head {
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 0px;

    }

    :root {
        --Primary-font: 'Quicksand', sans-serif;
    }

    .mobile-head img {
        width: 80px;
        margin-bottom: 10px;
    }

    .moobile-head p {
        padding: 0px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(155, 154, 155, 1);
        font-family: var(--primary-font);

    }

    .mobile-scroll {
        width: 75%;
        height: 100vh;
        background-color: white;
        left: 0;
        transition: 1s;
        color: #333333;
        overflow-y: scroll;
        text-align: left;
        top: 0rem;
        position: fixed;
        padding: 1rem;
        z-index: 1000;
        box-shadow: 10px 10px 5px #d3d3d31f;
    }

    .closeicon {
        font-size: 30px;
    }

    .mobile-scroll li {
        list-style: none;
        font-size: 20px;
        line-height: 35px;
        font-weight: bold;
        padding: 10px 0px;
        margin: 12px 0px;
        width: 200px;
        transition: 0.7s;
    }

    .mobile-scroll .down p {
        font-size: 18px;
        line-height: 25px;
        padding: 5px 0px;
        font-weight: bold;
        transition: 0.7s;
        text-align: justify;

    }

    .checkbtn .mobile-icon11 {
        display: none;
        position: absolute;
    }

    .mobile-nav #check:checked~.mobile-scroll {
        left: 0;
    }

    .mobile-nav #check:checked~.checkbtn .mobile-icon {
        display: none;
    }

    .mobile-nav #check:checked~.checkbtn .mobile-icon11 {
        display: block;
    }

    .mobile-scroll li:hover {
        background-color: #333333;
        color: white;

    }

    .mobile-scroll li[name] {
        position: relative;
    }

    .mobile-scroll li[name] .down {
        width: 250px;
        height: auto;
        position: absolute;

        border-radius: 10px;
        opacity: 0;
        transition: 0.5s;
        z-index: -1;
        background-image: url(../images/bg\ 1.jpg);
        background-size: cover;
        background-position: center;
        font-family: var(--primary-font);
        padding: 10px;
        top: 60px;
        left: 0px;
    }

    .mobile-scroll .active {
        background-color: #333333;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;

    }


    .mobile-scroll li[name]:hover .down {
        opacity: 1;
        z-index: 9999;
        box-shadow: 0 8px 32px 0 gainsboro;
    }

    .mobile-scroll li {
        padding: 5px 10px;
        transition: 0.5s;
        border-radius: 5px;
        cursor: pointer;
    }

    .mobile-scroll .down p {
        line-height: 30px;
        color: #333333 !important;
    }

    .mobile-scroll .down {
        width: 90%;

    }

    .checkbtn .mobile-icon11 {
        font-size: 25px;
    }

}

.myprofile {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
}