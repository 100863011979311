/* :root {
    --Primary-font: 'Quicksand', sans-serif;
}

.meet-leader {
    width: 100%;
    height: auto;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meet-leader1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meet-leader2 {
    width: 55%;
    height: 50%;
    text-align: center;
}

.meet-leader2 h1 {
    font-size: 20px;
    padding-bottom: 10px;
    color: var(--primary-color);
}

.meet-leader2 p {
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
}

.meetslider-scroll {
    width: 100%;
    height: auto;
    background-color: rgba(51, 51, 51, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    margin-top: 3rem;
    margin-bottom: 3rem;

}

.meetslider-scroll1 {
    width: 80%;
    height: auto;
}

.m-scroll {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;

}

.meetslider-scroll .m-scroll .attent-images {
    width: 220px;
    height: 220px;
}

.meetslider-scroll .m-scroll .attent-images img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.m-scroll .meet-slide1 {
    text-align: center;
    margin-bottom: 20px;

}

.meet-slide1 h1 {
    font-size: 15px;
    color: white;
    padding: 10px 0px;
}

.meet-slide1 p {
    font-size: 15px;
    line-height: 25px;
    padding-bottom: 10px;
}

.meet-slide1 h6 {


    font-size: 15px;

    color: white;
}

.meetslider-scroll3 {
    display: none;
}

@media(max-width:768px) {
    .meet-leader {
        width: auto;
        height: auto;
        padding: 20px;

        padding-top: 0;
        padding-bottom: 0px;
    }

    .meet-leader1 {
        width: 100%;
        height: 100%;

    }

    .meet-leader2 {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .meetslider-scroll {
        width: auto;
        height: auto;

        padding: 20px;

    }

    .meetslider-scroll1 {
        height: 100%;
        width: 100%;

    }

    .meetslider-scroll2 {
        width: 100%;
        height: 100%;

    }

    .m-scroll {

        width: 100%;
        height: auto;

    }

    .meetslider-scroll2 {
        display: none;
    }

    .meetslider-scroll3 {
        display: block;
    }

    .meet-slide1 {
        margin: 10px 0px;
    }

    .meet-slide1 h1 {
        color: black;
        color: white;
    }

    .meet-slide1 h6 {
        color: white
    }

    .meetslider-scroll1 .swiper-slide {
        background-color: #333333;

        height: 100%;
        padding: 0px;
    }

    .meetslider-scroll .m-scroll .attent-images {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .meetslider-scroll .m-scroll .attent-images img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
} */



:root {
    --Primary-font: 'Quicksand', sans-serif;
}

.meet-leader {
    width: 100%;
    height: auto;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meet-leader1 {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meet-leader2 {
    width: 55%;
    height: 50%;
    text-align: center;
}

.meet-leader2 h1 {
    font-size: 20px;
    padding-bottom: 10px;
    color: var(--primary-color);
}

.meet-leader2 p {
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
}

.meetslider-scroll {
    width: 100%;
    height: auto;
    background-color: rgba(51, 51, 51, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    margin-top: 3rem;
    margin-bottom: 3rem;

}

.meetslider-scroll1 {
    width: 80%;
    height: auto;
}

.m-scroll {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
   
}
.meetslider-scroll .m-scroll .attent-images{
    width: 220px;
    height: 220px;
}
.meetslider-scroll .m-scroll .attent-images img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.m-scroll .meet-slide1 {
    text-align: center;
    margin-bottom: 20px;
    
}

.meet-slide1 h1 {
    font-size: 15px;
    color: white;
    padding: 10px 0px;
}

.meet-slide1 p {
    font-size: 15px;
    line-height: 25px;
    padding-bottom: 10px;
}

.meet-slide1 h6 {


    font-size: 15px;

    color: white;
}

.meetslider-scroll3 {
    display: none;
}

@media(max-width:768px) {
    .meet-leader {
        width: auto;
        height: auto;
        padding: 20px;

        padding-top: 0;
        padding-bottom: 0px;
    }

    .meet-leader1 {
        width: 100%;
        height: 100%;

    }

    .meet-leader2 {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .meetslider-scroll {
        width: auto;
        height: auto;
        
        padding: 20px;

    }

    .meetslider-scroll1 {
        height: 100%;
        width: 100%;

    }

    .meetslider-scroll2 {
        width: 100%;
        height: 100%;

    }

    .m-scroll {

        width: 100%;
        height: auto;
        
    }

    .meetslider-scroll2 {
        display: none;
    }

    .meetslider-scroll3 {
        display: block;
    }
    .meet-slide1{
        margin: 10px 0px;
    }

    .meet-slide1 h1 {
        color: black;
        color: white;
    }

    .meet-slide1 h6 {
        color: white
    }
    .meetslider-scroll1 .swiper-slide{
        background-color: #333333;

        height: 100%;
        padding: 0px;
    }
    .meetslider-scroll .m-scroll .attent-images{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.meetslider-scroll .m-scroll .attent-images img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}
}