.wholediv {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.leadercontent {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0rem;
}

.leaderimage {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

}

.leaderimage::-webkit-scrollbar {
    width: 8px;

}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
    height: 50px;

}

.activeleader {
    background-color: gray;
    color: white;
}

/* .leaderimage::-webkit-scrollbar {
    display: ;
} */

.leadercontent11 {

    padding: 0px;
}

.leadercontent11 p {
    text-align: justify;
    padding-bottom: 10px;
    padding: 0;
}

.leadercontent11 h1 {
    padding-top: 10px;
    font-size: 25px;
}

.leadercontent11 p[name] {
    text-align: center;
    padding: 10px 0px;
}

.leaderscroll {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

}

.leaderscon12 {

    margin-top: 10px;
}

.leaderscon12 h1 {
    font-size: 20px;
    padding-bottom: 10px;
    text-align: start;

}

.leaderscon12 p {
    font-weight: bold;
    line-height: 25px;
    padding-bottom: 20px;
    color: gray;
    text-align: start;
}

.leaderscroll h1 {
    color: var(--primary-color);
    margin-left: 20px;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid grey;
    font-size: 18px;
}

.leaderimage img {
    width: 100%;
    height: 60%;
    object-fit: contain;
    margin-top: 0px;
    border-radius: 10px;
    padding-right: 6px;
}

.leadercont {
    text-align: center;
    line-height: 25px;
    padding: 10px 0px;
    padding-right: 6px;
}

.leadercont p {
    font-size: 16px;
    font-family: var(--primary-font);
    font-weight: bold;
    color: grey;
    padding: 5px 0px;
    text-align: justify;
}

.leadscroll {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    overflow-y: scroll;
    padding: 10px 0px;
    padding-left: 20px;
    margin-top: 10px;
}

.leadscroll::-webkit-scrollbar {
    display: none;
}

.leedscroll {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    margin-left: 0px;
    border-radius: 0px;
    margin-bottom: 20px;
    transition: .5s;
    width: 100%;
    height: auto;
    border: 1px solid gray;
    border-radius: 10px;
}

.leadscroll img {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 5px;
}

.leedscroll:hover {
    background-color: grey;
}

.leedscroll:hover .leadsdetail h3 {
    color: white;
}

.leedscroll:hover .leadsdetail p {
    color: white;
}

.leadsdetail {
    padding-left: 20px;
    line-height: 25px;
}

.leadsdetail h3 {
    font-size: 20px;
    transition: .5s;
    text-align: left;
}

.leadsdetail p {
    font-size: 16px;
    transition: .5s;
    text-align: left;
}

.leaderscon12 p[title] {
    padding-top: 20px;
}

/* 
@media screen and (max-width:768px) {
    .leadercontent {
        display: flow;
        width: 90%;
    }

    .leaderimage img {
        width: 100%;
        height: 20rem;
        object-fit: cover;
        margin-top: 10px;
        border-radius: 10px;
    }

    .leadercont {
        text-align: center;
        line-height: 25px;
        padding: 10px 0px;
    }

    .leaderscroll {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }

    .leaderscroll h1 {
        margin-left: 0;
    }

    .leadscroll {
        padding: 0;
    }


} */
@media screen and (max-width:768px) {
    .wholediv {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0rem;
        margin-bottom: 2rem;
        padding: 20px;

    }

    .leadercontent {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px;

    }

    .leaderimage {
        width: 100%;
        height: 500px;
        overflow-y: scroll;

    }

    .leaderimage::-webkit-scrollbar {
        display: none;
    }

    .leadercontent11 {

        padding: 0px;

    }

    .leadercontent11 p {
        text-align: justify;
        padding-bottom: 10px;
        padding: 0;
        font-size: 40px;
    }

    .leadercontent11 h1 {
        padding-top: 10px;
        font-size: 25px;
    }

    .leadercontent11 p[name] {
        text-align: center;
        padding: 10px 0px;
    }

    .leaderscroll {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        display: block;

        margin-top: 2rem;

    }

    .leaderscon12 {

        margin-top: 10px;
    }

    .leaderscon12 h1 {
        font-size: 20px;
        padding-bottom: 10px;

    }

    .leaderscon12 p {
        font-weight: bold;
        line-height: 25px;
        padding-bottom: 20px;
        color: gray;
    }

    .leaderscroll h1 {
        color: var(--primary-color);
        margin-left: 0rem;
        padding: 10px 0px;
        border-bottom: 1px solid grey;
        font-size: 18px;
        text-align: start;
        width: 120px;
    }

    .leaderimage img {
        width: 100%;
        height: 60%;
        object-fit: cover;
        margin-top: 10px;
    }

    .leadercont {
        text-align: center;
        line-height: 25px;
        padding: 0px;
    }

    .leadercont p {
        font-size: 16px;
        font-family: var(--primary-font);
        font-weight: bold;
        color: grey;
        padding: 5px 0px;

    }

    .leadscroll {
        width: 100%;
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        overflow-y: scroll;
        padding: 10px 0px;
        padding-left: 0px;
        margin-top: 10px;
    }

    .leadscroll::-webkit-scrollbar {
        display: none;
    }

    .leedscroll {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        margin-left: 20px;
        border-radius: 0px;
        margin-bottom: 20px;
        transition: .5s;
        width: 100%;
        height: auto;
        border-bottom: 1px solid gray;
        border-radius: 10px;
    }

    .leadscroll img {
        width: 90px;
    }

    .leedscroll:hover {
        background-color: grey;
    }

    .leedscroll:hover .leadsdetail h3 {
        color: white;
    }

    .leedscroll:hover .leadsdetail p {
        color: white;
    }

    .leadsdetail {
        padding-left: 20px;
        line-height: 25px;
    }

    .leadsdetail h3 {
        font-size: 20px;
        transition: .5s;
    }

    .leadsdetail p {
        font-size: 16px;
        transition: .5s;
    }

    .leaderscon12 p[title] {
        padding-top: 20px;
    }
}