#app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.officeimg {
  width: 50px !important;
  height: 50px !important;
}

.office-slider {
  width: 300px;
  height: 370px;
  position: relative;
  right: 0;
}

.office-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.office-slider .chairman-content {
  width: 100%;
  height: 80px;
  background-color: rgba(51, 51, 51, 1);
  color: white;
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.chairman-content h1 {
  font-size: 20px;
}

.chairman-content h5 {
  font-size: 14px;
  color: gray;
  padding: 8px 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.office-content {
  width: 90%;
  height: 100%;
  margin-right: 30px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.mySwiper {
  padding-bottom: 20px;
}

.office-content h1 {
  color: var(--primary-color);
  font-size: 20px;
  text-align: start;
}

.office-content p {
  font-size: 25px;
  padding: 10px 0px;
  font-weight: bold;
  text-align: justify;
  color: black;
}

.office-content p[name] {
  text-align: justify;
  font-size: 15px;
  line-height: 23px;
  color: #333333;
  width: 100%;
}

.office-con {
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  font-size: 15px;
  margin: 10px 0px 0px 0px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(217, 217, 217, 0.5);
}

.office-con img {
  width: 30px
}

.office-con p[title] {
  color: #333333;
  font-size: 15px;
  width: 100%;
  padding-left: 10px;
  line-height: 27px
}

.office {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  margin-top: 1rem;

}

.office1 {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;


}

.mySwiper {
  width: 100%;
  height: 100%;


}

.office-slider {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.office2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.occupationtag {
  margin-top: 5px;
  font-weight: bold;
  font-size: 15px;
  color: gray;
}

.office3 {

  width: 300px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 50px;
  margin-left: 20px;

}

@media screen and (max-width: 768px) {
  #app {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .office-slider {
    width: 300px;
    height: 370px;
    position: relative;
    right: 0;
  }

  .office-slider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .office-slider .chairman-content {
    width: 100%;
    height: 80px;
    background-color: rgba(51, 51, 51, 1);
    color: white;
    position: absolute;
    bottom: 20px;
    left: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .chairman-content h1 {
    font-size: 20px;
  }

  .chairman-content h5 {
    font-size: 14px;
    color: gray;
    padding: 8px 0px;
  }

  .office-slider .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background-color: white;
  }


  .office-content {
    width: 100%;
    height: 100%;
    margin-right: 0px;
    padding-right: 0px;





  }

  .swiper1 {
    background-color: white;
  }

  .mySwiper {
    padding-bottom: 20px;
  }

  .office-content h1 {
    color: var(--primary-color);
    font-size: 20px;
    text-align: start;
  }

  .office-content p {
    font-size: 25px;
    padding: 10px 0px;
    font-weight: bold;
    width: 300px;
    text-align: justify;
    color: black;
  }

  .office-content p[name] {
    text-align: justify;
    font-size: 15px;
    line-height: 23px;
    color: #333333;
    width: 100%;
  }

  .office-con {
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    font-size: 15px;
    margin: 10px 0px 0px 0px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(217, 217, 217, 0.5);
  }

  .office-con img {
    width: 30px
  }

  .office-con p[title] {
    color: #333333;
    font-size: 15px;
    width: 100%;
    padding-left: 10px;
    line-height: 27px
  }

  .office {
    width: auto;
    height: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;
    margin-top: 1rem;


  }

  .office1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;


  }

  .mySwiper {
    width: 100%;
    height: 100%;



  }

  .office-slider {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white
  }

  .office2 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-direction: column;



  }

  .office3 {
    width: 75%;
    height: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 30px 0px;

  }
}

.gallerydiv {
  padding: 2rem 9rem;
}

.galleryhead {
  text-align: left;
  margin-bottom: 1rem;
}

.gridsplitmember {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 2rem;
}

@media screen and (max-width:768px) {
  .gridsplitmember {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .gallerydiv {
    padding: 0rem 1rem;
  }
}