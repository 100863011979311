.gridsplit {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 8rem;
    row-gap: 2rem;
}

.leadername {
    margin-top: 0.5rem;
    color: black;
}

.historyleader {
    width: 22rem;
    height: 25rem;
    object-fit: cover;
}

@media screen and (max-width:768px) {
    .gridsplit {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}