* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: var(--primary-font);
  text-align: center;
}

.linktag {
  cursor: pointer;
}

.learnbtnleader {
  background-color: #D1112B;
  padding: 0.7rem 4rem;
  color: white;
  font-size: 12px;
  font-weight: 600;
  border: #D1112B;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.learnbtnleadernew {
  background-color: #D1112B;
  padding: 0.5rem 2rem;
  color: white;
  font-size: 12px;
  font-weight: 600;
  border: #D1112B;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.shortline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  /* height: 40px; */
}

.historyleadermember {
  height: 20rem;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.userdes {
  text-align: center;
  font-size: 12px;
  color: white;
  margin: 0;
}



.shortlinenew {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 100px;
}

.loginbtn {
  margin: 10px 0px;
  font-size: 16px;
  width: 400px;
  height: 35px;
  text-align: center;
  border: none;
  border-radius: 6px;
  color: white;
  font-family: var(--Primary-font);
  background-color: black;

}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  text-transform: capitalize;
}

.logoutag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.profilefamily {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}

.gallerymodel {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid red;
  background-color: white !important;
  overflow: auto;
  border-radius: 15px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  width: 55% !important;
  transform: translate(-50%, -50%);
  border: 1px solid lightgray !important;
  margin-top: -35px;
  height: auto;
  overflow: scroll;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  z-index: 100;
}

.galleryimg {
  width: 100%;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.viewmorebtn {
  width: 100px;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #000000;
  border: none;
  border-radius: 5px;
  font-family: var(--Primary-font);
  font-weight: bold;
  color: white;
}

#google_translate_element {
  padding: 1rem;
  z-index: 100;
  float: right;
  margin-bottom: -6rem;
  display: block !important;
}

.goog-te-combo {
  padding: 0.5rem;
  border: 1px solid gray;
  border-radius: 5px;
  font-weight: 700;
}

.checkboxinput {
  height: auto !important;
  width: auto !important;
}

.flexcheck {
  display: flex;
  width: 50%;
  gap: 1rem;
  align-items: baseline;
}

.advertisement-banner {
  display: none;
  position: relative;
}

.advertisement-banner.active {
  display: block;
  position: relative;
}

.addtag {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  background-color: gray;
  padding: 5px 10px;
  margin-top: 5px;
  font-size: 13px;
  border-radius: 0px 0px 0px 18px;
}

.loginadd {
  display: none;
}

.navabrad {
  height: 9rem;
  width: 25rem;
  object-fit: cover;
}

.homeimg {
  padding-left: 10rem;
  padding-right: 10rem;
  width: 100%;
  /* height: 22rem; */
  object-fit: cover;
}

.addnewtag {
  padding: 1rem;
  cursor: pointer;
}

@media screen and (max-width:768px) {
  .homeswiper1 {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider img {
    width: 100%;
    height: 12rem !important;
    object-fit: cover;
    border-radius: 10px;
  }

  .gallerymodel {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid red;
    background-color: white !important;
    overflow: auto;
    border-radius: 15px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    width: 95% !important;
    transform: translate(-50%, -50%);
    border: 1px solid lightgray !important;
    margin-top: -35px;
    height: auto;
    overflow: scroll;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    z-index: 100;
  }

  .homeswiper {
    width: 100%;
    height: 35vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    z-index: -1;
  }

  .mission-image {
    width: 100% !important;
    height: 60%;
    border-radius: 10px;
  }

  .home-mission1 {
    width: 80%;
    height: 100%;
    display: flow !important;
    align-items: center;
    justify-content: flex-start;
  }

  .mission-content {
    width: 100% !important;
    height: auto;
    padding-left: 0rem !important;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  .our-mission {
    width: 100%;
    height: auto;
    display: flow !important;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid gainsboro;
  }

  .home-history1 {
    width: 80%;
    height: 80%;
    display: flow !important;
    align-items: flex-start;
    justify-content: space-between;
  }

  .history-content {
    width: 100% !important;
    height: 100%;
    margin-right: 20px;
  }

  .history-images {
    width: 100% !important;
    height: 80%;
    border-radius: 10px;
  }

  .home-leader2 {
    width: 100% !important;
    text-align: center;
  }

  .home-news2 {
    width: 100% !important;
    height: 50%;
    text-align: center;
  }

  .news-scroll {
    width: 100% !important;
    height: 100%;
    margin-left: 0rem !important;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  .newsslider1 {
    width: 100%;
    height: 100%;
    display: flow !important;
    align-items: flex-start;
    padding-top: 0;
    justify-content: space-between;
  }

  .newsslider1 img {
    height: 13rem !important;
    width: 100% !important;
  }

  #google_translate_element {
    margin: 0.5rem;
    padding: 0.5rem;
    display: block !important;
    z-index: 100;
    position: relative;
  }

  .navbarflex {
    display: none !important;
  }

  .loginadd {
    margin-top: 20px;
    display: block;
  }

  .homeaddmobile {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5rem;
    object-fit: cover;

  }

  .loginimg {
    width: 22rem;
    height: 5rem;
    object-fit: cover;
  }

  .homeimg {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5rem;
    object-fit: cover;
  }
}

a {
  text-decoration: none;
}

.floating_btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 #42db87;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;
}


.text_icon {
  margin-top: 8px;
  color: #707070;
  font-size: 13px;
}