:root {
    --Primary-font: 'Quicksand', sans-serif;
}

.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: hidden;
}

.login1 {
    width: 90%;
    height: 100%;
    position: relative;
    z-index: 0;
}

.login1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.login1::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
}

.login2 {
    width: 100%;
    height: 100%;
    background-image: url(../images/bg\ 1.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo2 img {
    width: 100px;
    margin-bottom: 6px;
}

.logo2 p {
    padding: 3px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(155, 154, 155, 1);
}

.login-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.login-title h1 {
    font-size: 20px;
    padding: 10px;
    margin-top: 0px;
}

.inputss {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
}

.inputss input {
    width: 500px;
    height: 40px;
    padding-left: 10px;
    padding-bottom: 5px;
    border: none;
    font-size: 17px;
    color: black;
    font-weight: bold;
    font-size: 17px;
    font-family: var(--Primary-font);
    margin-bottom: 15px;
    outline: none;
    border-bottom: 1px solid black;
    background-color: transparent;
}

.inputss select {
    width: 500px;
    height: 40px;
    padding-left: 10px;
    padding-bottom: 5px;
    border: none;
    color: black;
    font-weight: bold;
    font-size: 17px;
    font-family: var(--Primary-font);
    margin-bottom: 15px;
    outline: none;
    border-bottom: 1px solid black;
    background-color: transparent;
}

.select__control {
    background-color: transparent !important;
    border: none !important;
    text-align: left !important;
    margin: -10px;
}

.select__menu {
    text-align: left !important;
    background-color: white !important;
}

.inputss .newselect {
    width: 500px;
    height: 40px;
    padding-left: 10px;
    padding-bottom: 5px;
    border: none;
    color: black;
    font-weight: bold;
    font-family: var(--Primary-font);
    margin-bottom: 15px;
    outline: none;
    border-bottom: 1px solid black;
    background-color: transparent;
    font-size: 17px;
}

.inputss textarea {
    width: 500px;
    height: 40px;
    padding-left: 10px;
    padding-bottom: 5px;
    border: none;
    color: black;
    font-weight: bold;
    font-family: var(--Primary-font);
    margin-bottom: 15px;
    outline: none;
    border-bottom: 1px solid black;
    background-color: transparent;
    font-size: 17px;
}


.login2 button {
    margin: 10px 0px;
    font-size: 16px;
    width: 500px;
    height: 40px;
    text-align: center;
    border: none;
    border-radius: 6px;
    color: white;
    font-family: var(--Primary-font);
    background-color: black;
    cursor: pointer;
    transition: 0.7s;
}

.login2 button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.iconss {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5px 0px;
}

.iconss .icon11 {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 8px;
    margin: 15px;
    border-radius: 100%;
    background-color: rgba(58, 88, 152, 1);
}

.iconss .icon22 {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 8px;
    margin: 15px;
    border-radius: 100%;
    background-color: rgba(205, 51, 50, 1);
}

.iconss .icon33 {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 8px;
    margin: 15px;
    border-radius: 100%;
    background-color: rgba(1, 172, 239, 1);

}

.iconss .icon44 {
    color: white;
    width: 40px;
    height: 40px;
    font-size: 20px;
    padding: 8px;
    margin: 15px;
    border-radius: 100%;
    background-color: rgba(254, 1, 0, 1);


}

.buttongrp {
    display: flex;
    gap: 1rem;
    width: 45%;
}

.profilepicimg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid black;
    margin: 10px;
}

.verifyimg {
    width: 20%;
}

.suggtag {
    color: #00b222;
    font-weight: bold;
    margin-top: 1rem;
}


.uploadlabel {
    text-align: left;

}

.makepayment {
    background-color: green !important;
    width: 110px !important;
    font-size: 12px !important;
    font-weight: 800 !important;
    margin: 0 !important;
    margin-left: 19rem !important;
}

.qrimg {
    width: 40%;
}

.paymentdiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.receiptinput {
    margin-top: 5px;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.donttag {
    animation: blink 1s infinite;
    font-size: 18px;
    font-weight: bold;
}

@media screen and (max-width:768px) {
    :root {
        --Primary-font: 'Quicksand', sans-serif;
    }

    .donttag {
        animation: blink 1s infinite;
        font-size: 15px;
        font-weight: bold;
    }

    .login {
        width: 100vw;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow-y: hidden;
        padding: 0px;
    }

    .login1 {
        width: 100%;
        height: 100%;
        position: relative;
        display: none;
    }

    .login1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .login1::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;

        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 1;
    }

    .inputss .newselect {
        width: 340px;
        height: 30px;
        padding-left: 10px;
        padding-bottom: 5px;
        border: none;
        color: black;
        font-weight: bold;
        font-family: var(--Primary-font);
        margin-bottom: 15px;
        outline: none;
        border-bottom: 1px solid black;
        background-color: transparent;
    }

    .flexcheck {
        display: flex;
        width: 100%;
        gap: 1rem;
        align-items: baseline;
    }

    .login2 {
        width: 100%;
        height: 100%;
        background-image: url(../images/bg\ 1.jpg);
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;

    }

    .logo2 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }

    .logo2 img {
        width: 100px;
        margin-bottom: 6px;
    }

    .logo2 p {
        padding: 3px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(155, 154, 155, 1);
    }

    .login-title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

    }

    .login-title h1 {
        font-size: 20px;
        padding: 10px;
        margin-top: 0px;
    }

    .inputss {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 5px;

    }

    .login2 button {
        margin: 10px 0px;
        font-size: 16px;
        width: 100%;
        height: 40px;
        text-align: center;
        border: none;
        border-radius: 6px;
        color: white;
        font-family: var(--Primary-font);
        background-color: black;
        cursor: pointer;
        transition: 0.7s;
    }

    .inputss input {
        width: 100%;
        height: 30px;
        padding-left: 10px;
        padding-bottom: 5px;
        border: none;
        color: black;
        font-weight: bold;
        font-family: var(--Primary-font);
        margin-bottom: 15px;
        outline: none;
        border-bottom: 1px solid black;
        background-color: transparent;

    }

    .inputss button {
        margin: 10px 0px;
        font-size: 16px;
        width: 100%;
        height: 25px;
        text-align: center;
        border: none;
        border-radius: 6px;
        color: white;
        font-family: var(--Primary-font);
        background-color: black;
        cursor: pointer;
        transition: 0.7s
    }

    .inputss select {
        width: 100%;
        height: 30px;
        padding-left: 10px;
        padding-bottom: 5px;
        border: none;
        color: black;
        font-weight: bold;
        font-family: var(--Primary-font);
        margin-bottom: 15px;
        outline: none;
        border-bottom: 1px solid black;
        background-color: transparent;
    }

    .inputss textarea {
        width: 100%;
        height: 30px;
        padding-left: 10px;
        padding-bottom: 5px;
        border: none;
        color: black;
        font-weight: bold;
        font-family: var(--Primary-font);
        margin-bottom: 15px;
        outline: none;
        border-bottom: 1px solid black;
        background-color: transparent;
    }

    .login2 button:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .buttongrp {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: 100%;
    }

    .iconss {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 5px 0px;
    }

    .iconss .icon11 {
        color: white;
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 8px;
        margin: 15px;
        border-radius: 100%;
        background-color: rgba(58, 88, 152, 1);
    }

    .iconss .icon22 {
        color: white;
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 8px;
        margin: 15px;
        border-radius: 100%;
        background-color: rgba(205, 51, 50, 1);
    }

    .iconss .icon33 {
        color: white;
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 8px;
        margin: 15px;
        border-radius: 100%;
        background-color: rgba(1, 172, 239, 1);

    }

    .iconss .icon44 {
        color: white;
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 8px;
        margin: 15px;
        border-radius: 100%;
        background-color: rgba(254, 1, 0, 1);


    }

    .login1 {
        display: none;
    }

    .makepayment {
        background-color: green !important;
        width: 110px !important;
        font-size: 12px !important;
        font-weight: 800 !important;
        margin: 0 !important;
        margin-left: 15rem !important;
    }

    .qrimg {
        width: 60%;
    }

}